import { FuelTypes } from "shared/constants";
import { successToast, warningToast } from "../Components/Toasts";
import apiGlobal, { handleApiError } from "../global/api.global";
import { queryKeyes } from "../shared/queryKeys";

export const fetchMachinaryOptions = async (VesselId: any = 0) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.Machinary.url(VesselId)
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadPortOptions = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.portMaster.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const loadDirectionOptions = async () => {
  try {
    const response = await apiGlobal.get(`/direction_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadCurrentDirectionOptions = async () => {
  try {
    const response = await apiGlobal.get(`/current_direction_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadCPWarrantiesFuelOptions = async (
  vesselId: number,
  voyageId: number,
  reportID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.lastCPWarranties.url(vesselId, voyageId, reportID)
    ); //`cp_warranties_data/get_cp_warranties_fuel_and_speed_data_by_vessel_voyage_reporting_id/?vessel_id=${vesselId}&voyage_id=${voyageId}&vessel_reporting_id=${reportID}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const load_Vessel_Types = async () => {
  try {
    const response = await apiGlobal.get(`vessel_type_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadVesselSubTypes = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.VesselSubTypeMaster.url()
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadCountries = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.CountryMaster.url()
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadVesselOwners = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.VesselOwnerMaster.url()
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadHullTypes = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.HullTypeMaster.url()
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const load_Fuel_Types = async () => {
  try {
    const response = await apiGlobal.get(`fuel_type_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadSensorOptions = async () => {
  try {
    const response = await apiGlobal.get(`sensor_list_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadMachineryOptions = async () => {
  try {
    const response = await apiGlobal.get(`machinery_list_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadReportTypeOptions = async () => {
  try {
    const response = await apiGlobal.get(`reporting_type_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadLoadConditionOptions = async () => {
  try {
    const response = await apiGlobal.get(`vessel_load_condition_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const loadStraitCanalOptions = async () => {
  try {
    const response = await apiGlobal.get(`strait_canal_transit_list_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const fuel_master = async () => {
  try {
    const response = await apiGlobal.get(`/fuel_master/`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const vesselReportingInfo = async (ReportID: number) => {
  try {
    const reponse = await apiGlobal.get(
      queryKeyes.vessel.reporting.url(ReportID)
    ); //`/vessel_reporting_information/${ReportID}`
    return reponse.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const vesselFuel = async (VesselID: number) => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.fuel.url(VesselID)); //`vessel_fuel_information/distinct_records_by_fuel_type/?vessel_id=${VesselID}`
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const allDecofficerReport = async (ReportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.decOfficerAll.url(ReportId)
    ); //`/all-reporting_data/${ReportId}`);
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

export const PreviousVesselReportingInfo = async (
  ReportID: number,
  VesselID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.PreviousVesselReport.url(ReportID, VesselID)
    ); //`/vessel_reporting_information/get_previous_by_id/?id=${ReportID}&vessel_id=${VesselID}`);
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const vesselVoyageReportingList = async (
  VesselID: number,
  VoyageID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.vesselVoyageReporting.url(VesselID, VoyageID)
    ); //`/vessel_reporting_information/get_reporting_info_same_vessel_and_voyage/?vessel_name_id=${VesselID}&&voyage_information_id=${VoyageID}`)
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Retruns Next Possible operation of report
 * @param number last_report_event_id
 * @param number current_possible_event_id
 * @returns
 */
export const nextPossibleOperations = async (
  current_possible_event_id: number,
  last_report_event_id: number
) => {
  try {
    if (current_possible_event_id && last_report_event_id) {
      const response = await apiGlobal.get(
        `/possible_operations_since_last_report/get_next_possible_operations/?current_possible_event_id=${current_possible_event_id}&last_report_event_id=${last_report_event_id}`
      );
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Function Returns next possible events
 * @param reporting_event
 * @returns
 */
export const nextPossibleEvents = async (reporting_event: number) => {
  try {
    if (reporting_event) {
      const response = await apiGlobal.get(
        `/event_management/get_next_events_by_event/?event_id=${reporting_event}`
      );
      return response.data;
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * Function to get last vessel reporting information vessel specific
 * @param number VesselID
 * @returns
 */
export const loadLastReportVessel = async (vesselId: number) => {
  try {
    if (vesselId > 0) {
      const response = await apiGlobal.get(
        queryKeyes.vessel.LastVesselReport.url(vesselId)
      );
      return response.data;
    }
    return [];
  } catch (error: any) {
    console.log(error?.message ?? "Error Occured");
    return [];
  }
};

/**
 * Common Submit Data function to do post
 * @param data
 * @param url
 * @returns
 */
export const commonPost = async (
  data: any,
  url: string,
  message: { success?: string; error?: string } = {}
) => {
  try {
    const response = await apiGlobal.post(url, data);
    if (response.status === 200 || response.status === 201) {
      successToast(message?.success ?? "Data saved successfully!");
    } else {
      warningToast(message?.error ?? "Unable to process reauest!");
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Common function to make patch request
 */
export const commonRequest = async (
  url: string,
  data: any,
  method: string,
  message: { success?: string; error?: string } = {}
) => {
  try {
    const response = await apiGlobal.request({
      url,
      method,
      data,
    });

    if (response.status === 200 || response.status === 201) {
      successToast(message?.success ?? "Data saved successfully!");
    }
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Funtions Loads fuel type ROB
 * @param vesselId
 * @param voyageId
 * @param reportID
 * @returns
 */
export const loadFuelTypeROB = async (
  vesselId: number,
  voyageId: number,
  reportID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FuelTypeROB.url(vesselId, voyageId, reportID)
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

/**
 * Returns Document Lists
 *
 * @param number cii_adjustment_event_id
 * @returns
 */
export const loadDocList = async (cii_adjustment_event_id: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIReportingDocuments.url(cii_adjustment_event_id)
    );
    //`cii_adjustment_event_document/get_cii_document_by_cii_adjustment_event/?cii_adjustment_event_id=${event.cii_adjustment}`);
    //setCurrEvent(response.data[0]);
    return response.data;
  } catch (err) {
    console.log(err);
  }
};

/**
 * Retruns cii event documents lists
 * @param vessel_reporting_cii_adjustment_id
 * @returns
 */
export const ciiEventDocuments = async (
  vessel_reporting_cii_adjustment_id: number
) => {
  try {
    if (vessel_reporting_cii_adjustment_id) {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CIIEventsSupportingFileData.url(
          vessel_reporting_cii_adjustment_id
        )
      );
      //`vessel_reporting_cii_adjustment_supporting_file/get_supporting_file_data_by_vessel_reporting_cii_adjustment/?vessel_reporting_cii_adjustment_id=${event.id}`);
      //setDocumentsSubmit(response.data);
      return response.data;
    }
    return [];
  } catch (err) {
    console.log(err);
  }
};

/**
 * function returns voyage list of vessels
 * @param VesselID
 * @returns
 */
export const voyageList = async (VesselID: number) => {
  if (VesselID > 0) {
    const response = await apiGlobal(
      queryKeyes.vessel.VoyageList.url(VesselID)
    );
    //`/voyage_information/get_voyage_by_vessel/?vessel_id=${VesselID}`)
    return response.data;
  }
};

/**
 * function returns lube oil ROB from previous record
 * @param vesselId
 * @param reportID
 * @returns
 */
export const loadPreviousLubeOilROB = async (
  vesselId: number,
  reportID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.PreviousLubeOilROB.url(vesselId, reportID)
    );
    return response.data;
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

/**
 * Returns the array with machinary fuel group of vessel
 * @returns
 */
export const loadMachineryFuelGroup = async (VesselID: number) => {
  try {
    if (VesselID) {
      const response = await apiGlobal(
        queryKeyes.vessel.MachinaryFuelGroup.url(VesselID)
      );
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Returns the array with machinary fuel group of vessel
 * @returns
 */
export const loadSpecialOperationFuelSettings = async (VesselID: number) => {
  try {
    if (VesselID) {
      const response = await apiGlobal(
        queryKeyes.vessel.SpecialOperationFuelSettings.url(VesselID)
      );
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Returns the array with machinary fuel group of vessel
 * @returns
 */
export const loadSpecialOperationEnergySettings = async (VesselID: number) => {
  try {
    if (VesselID) {
      const response = await apiGlobal(
        queryKeyes.vessel.SpecialOperationEnergySettings.url(VesselID)
      );
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Returns the array with machinary special operations of vessel
 * @returns
 */
export const loadSpecialOperationSettingList = async (VesselID: number) => {
  try {
    if (VesselID) {
      const response = await apiGlobal(
        queryKeyes.vessel.SpecialOperationSettingList.url(VesselID)
      );
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Returns the array with machinary energy group of vessel
 * @returns
 */
export const loadMachineryEnergyGroup = async (VesselID: number) => {
  try {
    if (VesselID) {
      const response = await apiGlobal(
        queryKeyes.vessel.MachinaryEnergyGroup.url(VesselID)
      );
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

/**
 * Get Vessel Machines
 * @param VesselID
 * @returns
 */
export const loadVesselMachineries = async (VesselID: number) => {
  try {
    if (!VesselID) return [];
    const response = await apiGlobal.get(
      queryKeyes.vessel.Machinary.url(VesselID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Get Vessel's Fule bunkaring batch
 * @param VesselID
 * @returns
 */
export const loadFuelBatches = async (VesselID: number) => {
  try {
    if (!VesselID) return [];
    const response = await apiGlobal.get(
      queryKeyes.vessel.Bunkaring.url(VesselID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Get Vessel Fuel Settings
 * @param VesselID
 * @returns
 */
export const loadFuelSettings = async (VesselID: number) => {
  try {
    if (!VesselID) return [];
    const response = await apiGlobal.get(
      queryKeyes.vessel.FuelSettings.url(VesselID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const loadVesselReportObject = async (reportId: number) => {
  try {
    if (reportId) {
      const response = await apiGlobal.get(
        queryKeyes.vessel.VesselReportingObject.url(reportId)
      );
      return response.data;
    }
    return [];
  } catch (error: any) {
    handleApiError(error);
    //throw error;
  }
};

/**
 * Fetch Fuel consumption batch based on the reporting id
 * @param reportId
 * @returns
 */
export const loadFuelConsputionBath = async (reportId: number) => {
  try {
    if (!reportId) {
      return [];
    }
    const response = await apiGlobal.get(
      queryKeyes.vessel.FuelConsumptionBatch.url(reportId)
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    return [];
  }
};

/**
 * Fetch Fuel ROB from previous report based on vesselId  & reportId
 * @param vesselId
 * @param reportId
 * @returns
 */
export const loadPreviousFuelROB = async (
  vesselId: number,
  reportId: number
) => {
  try {
    if (!reportId || !vesselId) {
      return [];
    }
    const response = await apiGlobal.get(
      queryKeyes.vessel.PreviousFuelROB.url(vesselId, reportId)
    );
    return response.data;
  } catch (error: any) {
    console.log(error);
    return [];
  }
};

/**
 * Loads EVMRU Related data
 *
 * @param number vesselId
 * @returns array
 */
export const loadEvmruData = async (vesselId: number) => {
  try {
    if (!vesselId) {
      return [];
    }
    const response = await apiGlobal.get(queryKeyes.vessel.Eumrv.url(vesselId));
    return response.data;
  } catch (error: any) {
    console.log(error);
    return [];
  }
};

/**
 * Load CII Adjustment data vessel specific
 *
 * @param number vesselId
 * @returns array
 */
export const loadCiiAssesmentGraphData = async (vesselId: number) => {
  try {
    if (!vesselId) {
      return [];
    }
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIAssessmentData.url(vesselId)
    ); //`/vessel_event_related_graph_data/${vesselId}`
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
/**
 * Load IMODCS data vessel specific
 *
 * @param number vesselId
 * @returns array
 */
export const loadImodcsGraphData = async (vesselId: number) => {
  try {
    if (!vesselId) {
      return [];
    }
    const response = await apiGlobal.get(
      queryKeyes.vessel.IMODCSData.url(vesselId)
    ); //`/vessel_event_related_graph_data/${vesselId}`
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const loadVessels = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.Vessels.url()); //`vessel_master/`
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load CII Adjustment additional events
 * @param eventId
 * @returns array
 */
export const loadAdditionalEvents = async (eventId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIAdjustmentAdditionalEvents.url(eventId)
    ); //`cii_adjustment_additional_events/get_next_possible_events_by_reporting_event/?reporting_event_id=eventId`
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's fuel consumption object
 * @param eventId
 * @returns array
 */
export const loadFuelConsumptionObject = async (
  vesselId: number,
  reportID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FuelConsumptionObject.url(vesselId, reportID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's batch consumption object
 * @param eventId
 * @returns array
 */
export const loadBatchConsumptionObject = async (
  vesselId: number,
  reportID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.BatchConsumptionObject.url(vesselId, reportID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's main engine settings
 * @param vesselId
 * @returns array
 */
export const loadMainEngineSettings = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.MainEngineSetting.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load engine types from master
 * @param -
 * @returns array
 */
export const loadEngineTypes = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.EngineTypeMaster.url()
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's steaming time
 * @param reportId
 * @returns object
 */
export const loadCurrentSteaingTime = async (reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CurrentSteamingTime.url(reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's distance travelled
 * @param reportId
 * @returns object
 */
export const loadCurrentDistanceTravelled = async (reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CurrentDistanceTravelled.url(reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load lube oils on vessel
 * @param vesselId
 * @returns array
 */
export const loadVesselLubeOils = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselLubeOils.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load previous report of fresh water
 * @param vesselId, reportId
 * @returns array
 */
export const loadPreviousFreshWater = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.PreviousFreshWater.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's drafts & displacement
 * @param vesselId, reportId
 * @returns array
 */
export const loadDraftsDisplacement = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.DraftsDisplacement.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's cargo details
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDetails = async (vesselId: number, reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoDetails.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's voyage parameter object
 * @param vesselId, reportId
 * @returns array
 */
export const loadVoyageParameterObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VoyageParameterObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's energy parameter object
 * @param vesselId, reportId
 * @returns array
 */
export const loadEnergyParameterObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.EnergyParameterObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's bunkering data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBunkeringObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.BunkeringObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load fuel types of a vessel
 * @param vesselId, reportId
 * @returns array
 */
export const loadDebunkeringObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.DebunkeringObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current vessel's fuel data
 * @param vesselId
 * @returns array
 */
export const loadVesselFuelTypes = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.vesselFuelType.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's lube oil bunkering data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLOBunkering = async (vesselId: number, reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LOBunkeringObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load ongoing STS operation of vessel
 * @param vesselId
 * @returns array
 */
export const loadSTSOngoingObject = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.STSOngoingObject.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's lube oil debunkering data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLODebunkeringObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LODebunkeringObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's fresh water data
 * @param vesselId, reportId
 * @returns array
 */
export const loadFreshWaterObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FreshWaterObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's lube oil rob data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLubeOilROBObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LubeOilROBObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's bill of lading data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBillOfLadingObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.BillOfLadingObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's ETAD data
 * @param vesselId, reportId
 * @returns array
 */
export const loadETADObject = async (vesselId: number, reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ETADObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load CII adjustment event's files that are not yet uploaded
 * @param vesselId
 * @returns array
 */
export const loadPendingCIIFileUpload = async (vesselId: number) => {
  try {
    if (vesselId > 0) {
      const response = await apiGlobal.get(
        queryKeyes.vessel.PendingCIIFileUpload.url(vesselId)
      );
      return response.data;
    }
    return [];
  } catch (error: any) {
    console.log(error?.message ?? "Error Occured!");
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo discharging fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoDischargingFuel = async (vesselId: number) => {
  try {
    if (vesselId > 0) {
      const response = await apiGlobal.get(
        queryKeyes.vessel.CargoDischargingFuelMachineries.url(vesselId)
      );
      return response.data;
    }
    return [];
  } catch (error: any) {
    console.log(error?.message ?? "Error Occured");
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo discharging energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoDischargingWithkWh = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoDischargingWithkWhMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load time periods for 4 hours table
 * @param -
 * @returns array
 */
export const loadTimePeriod = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TimePeriodObject.url
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's cargo discharging fuel data
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDischargingFuelObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoDischargingFuelObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's cargo discharging energy data
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDischargingEnergyObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoDischargingEnergyObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's cargo discharging energy without kWh meter data
 * @param vesselId, reportId
 * @returns array
 */
export const loadCargoDischarging4hrObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoDischarging4hrObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's auxiliary engine's max SFOC
 * @param vesselId
 * @returns array
 */
export const loadAuxEngineMaxSFOC = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.AuxEngineMaxSFOC.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's ballast details data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBallastDetailsObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.BallastDetailsObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's machineries selected for running hours
 * @param vesselId
 * @returns array
 */
export const loadVesselRunningHoursMachinery = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.vesselRunningHoursMachinery.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's machinery running hours' data
 * @param vesselId, reportId
 * @returns array
 */
export const loadMachineryRunningHoursObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.RunningHoursObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load fuel sub types
 * @param -
 * @returns array
 */
export const loadFuelSubTypes = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.FuelSubTypes.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's shore supply data
 * @param vesselId, reportId
 * @returns array
 */
export const loadShoreSupplyObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ShoreSupplyObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's main engine parameter data
 * @param vesselId, reportId
 * @returns array
 */
export const loadMainEngineParameterObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.MainEngineParameterObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's auxilliary engine parameter data
 * @param vesselId, reportId
 * @returns array
 */
export const loadAuxEngineParameterObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.AuxEngineParameterObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's thermal oil heater data
 * @param vesselId, reportId
 * @returns array
 */
export const loadThermalOilHeaterObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ThermalOilHeaterObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's anchorage data
 * @param vesselId, reportId
 * @returns array
 */
export const loadAnchorageObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.AnchorageObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's pilot arrival details' data
 * @param vesselId, reportId
 * @returns array
 */
export const loadPilotArrivalDetailsObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.PilotArrivalDetailsObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's pilot departure details' data
 * @param vesselId, reportId
 * @returns array
 */
export const loadPilotDepartureDetailsObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.PilotDepartureDetailsObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's tugs usage data
 * @param vesselId, reportId
 * @returns array
 */
export const loadTugsUsageObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TugsUsageObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's other details data
 * @param vesselId, reportId
 * @returns array
 */
export const loadOtherDetailsObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.OtherDetailsObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's scrubing unit data
 * @param vesselId, reportId
 * @returns array
 */
export const loadScrubingUnitObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ScrubingUnitObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's sludge data
 * @param vesselId, reportId
 * @returns array
 */
export const loadSludgeObject = async (vesselId: number, reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.SludgeObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's bilge data
 * @param vesselId, reportId
 * @returns array
 */
export const loadBilgeObject = async (vesselId: number, reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.BilgeObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load port activities
 * @param -
 * @returns array
 */
export const loadPortActivities = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.PortActivities.url()
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's port activities data
 * @param vesselId, reportId
 * @returns array
 */
export const loadPortActivitiesObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.PortActivitiesObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load LNG Elements
 * @param -
 * @returns array
 */
export const loadLNGElements = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.LNGElements.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current voyage's LNG Cargo Quality data
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGCargoQualityObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LNGCargoQualityObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's basic information use for IMO DCS, EU MRV, UK MRV
 * @param vesselId
 * @returns array
 */
export const loadVesselBasicInformation = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselBasicInformation.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo heating fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoHeatingFuel = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoHeatingFuelMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo heating energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoHeatingWithkWh = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoHeatingWithkWhMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Heating fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoHeatingFuelObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoHeatingFuelObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Heating energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoHeatingEnergyObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoHeatingEnergyObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Heating 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoHeating4hrObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoHeating4hrObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo cooling fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoCoolingFuel = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoCoolingFuelMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo cooling energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoCoolingWithkWh = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoCoolingWithkWhMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Cooling fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoCoolingFuelObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoCoolingFuelObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Cooling energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoCoolingEnergyObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoCoolingEnergyObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Cooling 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoCooling4hrObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoCooling4hrObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo reliquification fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoReliquificationFuel = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoReliquificationFuelMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for cargo reliquification energy consumption
 * @param vesselId
 * @returns array
 */
export const loadCargoReliquificationWithkWh = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoReliquificationWithkWhMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Reliquification fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoReliquificationFuelObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoReliquificationFuelObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Reliquification energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoReliquificationEnergyObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoReliquificationEnergyObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Cargo Reliquification 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadCargoReliquification4hrObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CargoReliquification4hrObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for tank cleaning fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadTankCleaningFuel = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TankCleaningFuelMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for tank cleaning energy consumption
 * @param vesselId
 * @returns array
 */
export const loadTankCleaningWithkWh = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TankCleaningWithkWhMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Tank Cleaning fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadTankCleaningFuelObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TankCleaningFuelObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Tank Cleaning energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadTankCleaningEnergyObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TankCleaningEnergyObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Tank Cleaning 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadTankCleaning4hrObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.TankCleaning4hrObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for reefer container fuel consumption
 * @param vesselId
 * @returns array
 */
export const loadReeferContainerFuel = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ReeferContainerFuelMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel machineries applicable for reefer container energy consumption
 * @param vesselId
 * @returns array
 */
export const loadReeferContainerWithkWh = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ReeferContainerWithkWhMachineries.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Reefer Container fuel data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadReeferContainerFuelObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ReeferContainerFuelObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Reefer Container energy data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadReeferContainerEnergyObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ReeferContainerEnergyObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Reefer Container 4 hr data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadReeferContainer4hrObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ReeferContainer4hrObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Reefer Container power rating data
 * @param vesselId, voyageId
 * @returns array
 */
export const loadReeferContainerPowerRatingObject = async (
  vesselId: number,
  voyageId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ReeferContainerPowerRatingObject.url(vesselId, voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load Fleets
 * @param -
 * @returns array
 */
export const loadFleets = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.Fleets.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load Sludge tanks
 * @param -
 * @returns array
 */
export const loadSludgeTanks = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.Sludge.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load Bilge tanks
 * @param -
 * @returns array
 */
export const loadBilgeTanks = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.Bilge.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessels' card details
 * @param vesselId
 * @returns array
 */
export const loadVesselCardDetails = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselCardDetails.url()
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Other Special Operation's fuel consumption data
 * @param vesselId, reportId
 * @returns array
 */
export const loadOtherSpOperationFuel = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.OtherSpOperationFuelObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Other Special Operation's energy consumption data
 * @param vesselId, reportId
 * @returns array
 */
export const loadOtherSpOperationEnergy = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.OtherSpOperationEnergyObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's auxilliary engines
 * @param vesselId
 * @returns array
 */
export const loadVesselAuxEngines = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselAuxEngines.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load fleet's vessels
 * @param -
 * @returns array
 */
export const loadFleetVessels = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.FleetVessels.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's Weather data
 * @param vesselId, reportId
 * @returns array
 */
export const loadWeatherData = async (vesselId: number, reportId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.WeatherDataObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current vessel's EEOI
 * @param vesselId
 * @returns object
 */
export const loadEEOI = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.EEOI.url(vesselId));
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current fleet's graph data
 * @param fleetId
 * @returns object
 */
export const loadFleetGraphData = async (fleetId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FleetGraphData.url(fleetId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel assigned to user data
 * @param
 * @returns array
 */
export const loadUserVessel = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.UserVessel.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's CII data
 * @param vesselId
 * @returns array
 */
export const loadCIIData = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIData.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's FC Electrical Reefer Container data
 * @param vesselId, reportId
 * @returns array
 */
export const loadFCElectricalReeferData = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.FCElectricalReeferObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's CII Adjustment reports
 * @param vesselId
 * @returns array
 */
export const loadCIIAdjustmentData = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIAdjustment.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's CII Adjustment reports that will be updated
 * @param vesselId, ciiAdjustmentId
 * @returns array
 */
export const loadCIIAdjustmentUpdate = async (
  vesselId: number,
  ciiAdjustmentId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.CIIAdjustmentUpdate.url(vesselId, ciiAdjustmentId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load year's whose vessel data is available
 * @param vesselId
 * @returns array
 */
export const loadVesselYear = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselYear.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load sts data according to vessel
 * @param vesselId
 * @returns array
 */
export const loadSTSAdjustment = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.STSAdjustment.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load sts adjustment approval data
 * @param stsId
 * @returns array
 */
export const loadSTSAdjustmentApprovalData = async (stsId: number) => {
  const response = await apiGlobal.get(
    queryKeyes.vessel.STSAdjustmentApprovalData.url(stsId)
  );
  return response.data;
};

/**
 * Load LNG cargo ROB from previous report
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGCargoPreviousROB = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LNGCargoPreviousROB.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's LNG cargo ROB
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGCargoROBObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LNGCargoROBObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current correction's initial cargo ROB
 * @param vesselId
 * @returns array
 */
export const loadInitialLNGCargoROBObject = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.InitialLNGCargoROBObject.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's general info object
 * @param vesselId
 * @returns array
 */
export const loadVesselGenInfoObject = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselGenInfoObject.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's LNG bunkering object
 * @param vesselId
 * @returns array
 */
export const loadVesselLNGBunkeringObject = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselLNGBunkeringObject.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};
/**
 * Load vessel's lng tank
 * @param vesselId
 * @returns array
 */
export const loadVesselLNGtanks = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselLNGTanks.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's lng tank details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadLNGTankDetailsObject = async (
  vesselId: number,
  reportID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LNGTankDetailsObject.url(vesselId, reportID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load special operations
 * @param constant
 * @returns array
 */
export const loadSpecialOperation = async (constant: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.SpecialOperationMaster.url(constant)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load Vessel's other setting object
 * @param vesselId
 * @returns array
 */
export const loadVesselOtherSettingsOperation = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselOtherSettingsObject.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's steam dump details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadSteamDumpDetailsObject = async (
  vesselId: number,
  reportID: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.SteamDumpDetailsObject.url(vesselId, reportID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's fuel sub type consumption according to special operation object
 * @param vesselId, reportId, spOperationId
 * @returns array
 */
export const loadSpOpFuelSubTypeObject = async (
  vesselId: number,
  reportID: number,
  spOperationId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.SpOpFuelSubTypeObject.url(
        vesselId,
        reportID,
        spOperationId
      )
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's average load details
 * @param vesselId
 * @returns array
 */
export const loadVesselAvgLoadInfo = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselAvgLoadInfo.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's average load details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadAvgInfoObject = async (vesselId: number, reportID: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.AvgLoadDetailsObject.url(vesselId, reportID)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current report's additional details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadProactiveAddDetailsObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ProactiveAddDetailsObject.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load current voyage's object
 * @param voyageId
 * @returns array
 */
export const loadVoyageObject = async (voyageId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VoyageObject.url(voyageId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load vessel's latest sent report's date
 * @param vesselId
 * @returns object
 */
export const loadLastUpdatedOn = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.LastUpdatedOn.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load previous report's additional details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadProactivePreviousAddDetailsObject = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ProactivePreviousAddDetailsObject.url(
        vesselId,
        reportId
      )
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load auxillary machine settings
 * @param vesselId
 * @returns array
 */
export const loadAuxillaryEngineSetting = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.AuxillaryMachineSetting.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load previous report's additional details object
 * @param vesselId, reportId
 * @returns array
 */
export const loadProactiveETAAdditionalDetail = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.ProactiveETAAdditionalDetail.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load other ports' list
 * @param -
 * @returns array
 */
export const loadOtherPorts = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.masters.OtherPortMaster.url()
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load Vessel Sensor List
 * @param vesselId
 * @returns array
 */
export const loadVesselSensorList = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.VesselSensorList.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * previous End of Sea Passage's arrival port
 * @param vesselId, reportId
 * @returns array
 */
export const loadPreviousEOSPPort = async (
  vesselId: number,
  reportId: number
) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.PreviousEOSPPort.url(vesselId, reportId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const loadBDNFileUploadList = async (vesselId: number) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.vessel.BDNFileUploadList.url(vesselId)
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Get current year
 * @returns object
 */
export const loadCurrentYear = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.vessel.CurrentYear.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/**
 * Load years from year master
 * @returns array
 */
export const loadYears = async () => {
  try {
    const response = await apiGlobal.get(queryKeyes.masters.year.url());
    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
};

/** Fetches the list of all sync file statuses from the server.*/
export const syncFileStatus = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.sync.SyncFileStatusList.url()
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

/** Fetches the status of a specific file based on the provided file name.*/
export const syncFileStatusByFileName = async (fileName: string) => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.sync.syncFileStatusByFileName.url(fileName)
    );
    return response.data;
  } catch (error) {
    return [];
  }
};

export const NotificationList = async () => {
  try {
    const response = await apiGlobal.get(
      queryKeyes.notification.NotificationList.url()
    );
    return response.data;
  } catch (error) {
    return [];
  }
};
