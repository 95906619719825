import React from 'react';
import { Badge } from 'reactstrap';

export interface BadgeProps {
    text: string;
    type: 'category' | 'priority';
    categoryColors: { [key: string]: string };
    priorityColors: { [key: string]: string };
  }

const BadgeComponent: React.FC<BadgeProps> = ({ text, type }) => {
    const categoryColors: { [key: string]: string } = {
        Maintainance: 'info',
        Critical: 'warning',
        System: 'success',
        Daily_Reporting: 'primary',
    };

    const priorityColors: { [key: string]: string } = {
        High: 'danger',
        Medium: 'warning',
        Low: 'secondary',
    };

    const colors = type === 'category' ? categoryColors : priorityColors;
    return <Badge color={colors[text]}>{text}</Badge>;
};

export default BadgeComponent;
