// EmailDetails.tsx
import React from 'react';

interface EmailDetailsProps {
  email: {
    id: number;
    subject: string;
    date: string;
    category: string;
    priority: string;
  } | null;
  handleBackClick: () => void;
}

const EmailDetails: React.FC<EmailDetailsProps> = ({ email, handleBackClick }) => {
  if (!email) return <div>Select an email to view details</div>;

  return (
    <div className="email-details-container">
      <button onClick={handleBackClick} className="btn btn-secondary mb-3">Back to Inbox</button>
      <h5>Email Details</h5>
      <p><strong>Subject:</strong> {email.subject}</p>
      <p><strong>Date:</strong> {email.date}</p>
      <p><strong>Category:</strong> {email.category}</p>
      <p><strong>Priority:</strong> {email.priority}</p>
    </div>
  );
};

export default EmailDetails;
