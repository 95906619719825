import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link } from "react-router-dom";
import classname from "classnames";
import withRouter from "../Components/withRouter";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import env from "environment/env";
import { hasRole } from "utils/auth/authUtils";
import { Roles, VesselTypeConstant } from "shared/constants";
import { RootState } from "index";

const Navbar = (props: any) => {
    const { Vessels, VesselID } = useSelector(
        (state: RootState) => state.Reporting
    );
    const vessel = Vessels.find((rec: any) => rec.id === VesselID);
    const { leftMenu } = useSelector((state: any) => ({
        leftMenu: state.Layout.leftMenu,
    }));
    const [masters, setMasters] = useState<boolean>(false);
    const [analysis, setAnalysis] = useState<boolean>(false);
    const [adjustments, setAdjustments] = useState<boolean>(false);
    const [simulator, setSimulator] = useState<boolean>(false);
    const [reports, setReports] = useState<boolean>(false);
    const location = useLocation();
    const pathName = location.pathname;

    useEffect(() => {
        let matchingMenuItem = null;
        const ul: any = document.getElementById("navigation");
        const items: any = ul.getElementsByTagName("a");
        removeActivation(items);
        for (let i = 0; i < items.length; ++i) {
            if (window.location.href === items[i].href) {
                matchingMenuItem = items[i];
                break;
            }
        }
        if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem);
        }
    }, [pathName]);

    function activateParentDropdown(item: any) {
        item.classList.add("active");
        const parent = item.closest(".dropdown-menu");
        if (parent) {
            parent.classList.add("active"); // li
            const parent2 = parent.parentElement;
            parent2.classList.add("active"); // li
            const parent3 = parent2.parentElement;
            if (parent3) {
                parent3.classList.add("active"); // li
                const parent4 = parent3.parentElement;
                if (parent4) {
                    parent4.classList.add("active"); // li
                    const parent5 = parent4.parentElement;
                    if (parent5) {
                        parent5.classList.add("active"); // li
                        const parent6 = parent5.parentElement;
                        if (parent6) {
                            parent6.classList.add("active"); // li
                        }
                    }
                }
            }
        }
        return false;
    }

    const removeActivation = (items: any) => {
        for (var i = 0; i < items.length; ++i) {
            var item = items[i];
            const parent = items[i].parentElement;
            if (item && item.classList.contains("active")) {
                item.classList.remove("active");
            }
            if (parent) {
                if (parent.classList.contains("active")) {
                    parent.classList.remove("active");
                }
            }
        }
    };

    // /**
    //  * Add Active class to the menu
    //  *
    //  * @param target
    //  * @returns
    //  */
    // const activeMenu = (target: any) => {
    //     return (location.pathname.indexOf(target) === 0) ? ' active ' : '';
    // }
    return (
        <React.Fragment>
            <div className="topnav">
                <div className="container-fluid">
                    <nav
                        className="navbar navbar-light navbar-expand-lg topnav-menu"
                        id="navigation"
                    >
                        <Collapse
                            isOpen={leftMenu}
                            className="navbar-collapse"
                            id="topnav-menu-content"
                        >
                            <ul className="navbar-nav">
                                {(hasRole(Roles.ES_ADMIN) ||
                                    hasRole(Roles.MANAGER) ||
                                    hasRole(Roles.MASTER) ||
                                    hasRole(Roles.CHIEFENGINEER)) && (
                                        <li className="nav-item dropdown">
                                            <Link
                                                className={"nav-link dropdown-toggle arrow-none"}
                                                to="/dashboard"
                                            >
                                                <span>Dashboard</span>
                                            </Link>
                                        </li>
                                    )}
                                {env?.masters === true && hasRole(Roles.ES_ADMIN) && (
                                    <li className="nav-item dropdown">
                                        <Link
                                            to="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setMasters(!masters);
                                            }}
                                            className={"nav-link dropdown-toggle arrow-none"}
                                        >
                                            Super Master <div className="arrow-down"></div>
                                        </Link>
                                        <div
                                            className={classname(
                                                "dropdown-menu mega-dropdown-menu dropdown-menu-left dropdown-mega-menu-xl",
                                                { show: masters }
                                            )}
                                        >
                                            <div className="ps-2 p-lg-0">
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/region_master"
                                                            className="dropdown-item"
                                                        >
                                                            Region Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/contact_type_master"
                                                            className="dropdown-item"
                                                        >
                                                            Contact Type Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/fuel_type_master"
                                                            className="dropdown-item"
                                                        >
                                                            Fuel Type Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/fuel_sub_type_master"
                                                            className="dropdown-item"
                                                        >
                                                            Fuel Sub Type Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/vessel_type_master"
                                                            className="dropdown-item"
                                                        >
                                                            Vessel Type Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/hull_type_master"
                                                            className="dropdown-item"
                                                        >
                                                            Hull Type Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/direction_master"
                                                            className="dropdown-item"
                                                        >
                                                            Direction Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/wind_direction_master"
                                                            className="dropdown-item"
                                                        >
                                                            Wind Direction Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/vessel_load_condition_master"
                                                            className="dropdown-item"
                                                        >
                                                            Load Condition Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/beaufort_scale_master"
                                                            className="dropdown-item"
                                                        >
                                                            Beaufort Scale Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/strait_canal_transit_list_master"
                                                            className="dropdown-item"
                                                        >
                                                            Strait Canal Transit
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/country_master"
                                                            className="dropdown-item"
                                                        >
                                                            Country Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/vessel_owner_master"
                                                            className="dropdown-item"
                                                        >
                                                            Vessel Owner Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/fuel_master"
                                                            className="dropdown-item"
                                                        >
                                                            Fuel Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/vessel_sub_type_master"
                                                            className="dropdown-item"
                                                        >
                                                            Vessel Sub Type Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/port_master"
                                                            className="dropdown-item"
                                                        >
                                                            Port Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/cii_adjustment_events_master"
                                                            className="dropdown-item"
                                                        >
                                                            CII Adjustment Event Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/machinery_list_master"
                                                            className="dropdown-item"
                                                        >
                                                            Machinery List Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/vessel_master"
                                                            className="dropdown-item"
                                                        >
                                                            Vessel Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/lube_oil_list_master"
                                                            className="dropdown-item"
                                                        >
                                                            Lube Oil List Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/sensor_list_master"
                                                            className="dropdown-item"
                                                        >
                                                            Sensor List Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/current_direction_master"
                                                            className="dropdown-item"
                                                        >
                                                            Current Direction Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/douglas_sea_scale_master"
                                                            className="dropdown-item"
                                                        >
                                                            Douglas Sea Scale Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/reporting_type_master"
                                                            className="dropdown-item"
                                                        >
                                                            Reporting Type Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/reporting_events_master"
                                                            className="dropdown-item"
                                                        >
                                                            Reporting Events Master
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/reporting_operation_mode_master"
                                                            className="dropdown-item"
                                                        >
                                                            Reporting Operation
                                                        </Link>
                                                    </Col>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/reporting_cii_exclusions_master"
                                                            className="dropdown-item"
                                                        >
                                                            Reporting CII Exclusion
                                                        </Link>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col lg={4}>
                                                        <Link
                                                            to="/masters/fuel_category_master"
                                                            className="dropdown-item"
                                                        >
                                                            Fuel Category Master
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {hasRole(Roles.ES_ADMIN) && (
                                    // hasRole(Roles.MANAGER)) &&
                                    <li className="nav-item dropdown">
                                        <Link
                                            className={"nav-link dropdown-toggle arrow-none"}
                                            to="/fleet_management"
                                        >
                                            <span>Fleet Assessment</span>
                                        </Link>
                                    </li>
                                )}
                                {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)) && (
                                    <li className="nav-item dropdown">
                                        <Link
                                            to="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAnalysis(!analysis);
                                            }}
                                            className="nav-link dropdown-toggle arrow-none"
                                        >
                                            Emission Analysis<div className="arrow-down"></div>
                                        </Link>
                                        <div
                                            className={classname(
                                                "dropdown-menu mega-dropdown-menu dropdown-menu-right dropdown-mega-menu-md",
                                                { show: analysis }
                                            )}
                                        >
                                            <div className="ps-2 p-lg-0">
                                                <Row>
                                                    <Col lg={2}>
                                                        <Link
                                                            className={"dropdown-item"}
                                                            to="/cii_assessment"
                                                        >
                                                            <span>CII Assessment</span>
                                                        </Link>
                                                        <Link className={"dropdown-item"} to="/imo_dcs">
                                                            <span>IMO DCS</span>
                                                        </Link>
                                                        <Link className={"dropdown-item"} to="/eu_mrv_ets">
                                                            <span>EU MRV/ETS</span>
                                                        </Link>
                                                        <Link className={"dropdown-item"} to="/uk_mrv">
                                                            <span>UK MRV</span>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {hasRole(Roles.ES_ADMIN) && (
                                    // hasRole(Roles.MANAGER))
                                    <li className="nav-item dropdown">
                                        <Link
                                            to="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setReports(!reports);
                                            }}
                                            className="nav-link dropdown-toggle arrow-none"
                                        >
                                            Reports<div className="arrow-down"></div>
                                        </Link>
                                        <div
                                            className={classname(
                                                "dropdown-menu mega-dropdown-menu dropdown-menu-right dropdown-mega-menu-md",
                                                { show: analysis }
                                            )}
                                        >
                                            <div className="ps-2 p-lg-0">
                                                <Row>
                                                    <Col lg={2}>
                                                        <Link
                                                            className={"dropdown-item"}
                                                            to="/custom_report_generation"
                                                        >
                                                            <span>Custom report</span>
                                                        </Link>
                                                        <Link
                                                            className={"dropdown-item"}
                                                            to="/preconfigured_report_generation"
                                                        >
                                                            <span>Pre-configured report</span>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {/* {(hasRole(Roles.ES_ADMIN) ||
                                hasRole(Roles.MANAGER)) &&
                                <li className="nav-item dropdown">
                                    <Link
                                        className="nav-link dropdown-toggle arrow-none"
                                        to="/vessel_performance"
                                    >
                                        <span>Vessel Performance</span>
                                    </Link>
                                </li>
                            } */}
                                {(hasRole(Roles.ES_ADMIN) ||
                                    hasRole(Roles.MANAGER) ||
                                    hasRole(Roles.MASTER) ||
                                    hasRole(Roles.CHIEFENGINEER)) && (
                                        <li className="nav-item dropdown">
                                            <Link
                                                to="/masters/vessel_master"
                                                className={"nav-link dropdown-toggle arrow-none"}
                                            >
                                                Vessel Reporting
                                            </Link>
                                        </li>
                                    )}
                                {hasRole(Roles.ES_ADMIN) && (
                                    <li className="nav-item dropdown">
                                        <Link
                                            to="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAdjustments(!adjustments);
                                            }}
                                            className="nav-link dropdown-toggle arrow-none"
                                        >
                                            Adjustments<div className="arrow-down"></div>
                                        </Link>
                                        <div
                                            className={classname(
                                                "dropdown-menu mega-dropdown-menu dropdown-menu-right dropdown-mega-menu-md",
                                                { show: adjustments }
                                            )}
                                        >
                                            <div className="ps-2 p-lg-0">
                                                <Row>
                                                    <Col lg={2}>
                                                        <Link
                                                            to="/voyage_adjustment"
                                                            className="dropdown-item"
                                                        >
                                                            Voyage Adjustment
                                                        </Link>
                                                        {vessel?.vessel_type ===
                                                            VesselTypeConstant.TANKER && (
                                                                <Link
                                                                    to="/sts_adjustment"
                                                                    className="dropdown-item"
                                                                >
                                                                    STS Adjustment
                                                                </Link>
                                                            )}
                                                        <Link
                                                            to="/FC(elec+boiler+other)_adjustment"
                                                            className="dropdown-item"
                                                        >
                                                            FC<sub>(elec + boiler + other)</sub>
                                                        </Link>
                                                        {vessel?.vessel_type ===
                                                            VesselTypeConstant.LNG_CARRIER && (
                                                                <Link
                                                                    to="/n2_adjustment"
                                                                    className="dropdown-item"
                                                                >
                                                                    N<sub>2</sub> Adjustment
                                                                </Link>
                                                            )}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </li>
                                )}

                                {hasRole(Roles.ES_ADMIN) && (
                                    <li className="nav-item dropdown">
                                        <Link
                                            to="/#"
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setSimulator(!simulator);
                                            }}
                                            className="nav-link dropdown-toggle arrow-none"
                                        >
                                            Simulator<div className="arrow-down"></div>
                                        </Link>
                                        <div
                                            className={classname(
                                                "dropdown-menu mega-dropdown-menu dropdown-menu-right dropdown-mega-menu-md",
                                                { show: simulator }
                                            )}
                                        >
                                            <div className="ps-2 p-lg-0">
                                                <Row>
                                                    <Col lg={2}>
                                                        <Link to="/cii_simulator" className="dropdown-item">
                                                            CII Simulator
                                                        </Link>
                                                        <Link
                                                            to="/voyage_estimator"
                                                            className="dropdown-item"
                                                        >
                                                            Voyage Estimator
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </li>
                                )}
                                {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)
                                    || hasRole(Roles.MASTER)
                                    || hasRole(Roles.CHIEFENGINEER)) && (env.desktop_app === true) && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/sync_report">
                                                SyncReport
                                            </Link>
                                        </li>
                                    )}
                                    {(hasRole(Roles.ES_ADMIN) || hasRole(Roles.MANAGER)
                                    || hasRole(Roles.MASTER)
                                    || hasRole(Roles.CHIEFENGINEER)) && (
                                        <li className="nav-item">
                                            <Link className="nav-link" to="/notifications">
                                                Notification
                                            </Link>
                                        </li>
                                    )}
                            </ul>
                        </Collapse>
                    </nav>
                </div>
            </div>
        </React.Fragment>
    );
};

Navbar.propTypes = {
    leftMenu: PropTypes.any,
    location: PropTypes.any,
    menuOpen: PropTypes.any,
    t: PropTypes.any,
};

export default withRouter(Navbar);
