import ErrorComponent from 'Components/ErrorComponent';
import Loading from 'Components/Loading';
import { errResponse, formatBytes } from 'GenericForms/Helper';
import React, { useState } from 'react'
import Dropzone from 'react-dropzone';
import { useQuery } from 'react-query';
import { Row, Col, Button, Card, CardHeader, CardBody, Form, Label } from 'reactstrap'
import { queryKeyes } from 'shared/queryKeys';
import { loadBunkeringObject } from 'VesselMaster/vesselMaster.hooks';
import AsyncSelect from "react-select/async";
import { customStyle } from 'shared/CommonCSS';
import { FieldArray, Formik } from 'formik';
import FormValuesDebug from 'utils/debugTools/FormValuesDebug';
import { errorToast, successToast } from 'Components/Toasts';
import apiGlobal, { apiMedia } from 'global/api.global';
import { BDNConstant } from 'shared/constants';

interface BunkeringType {
    ReportID: number,
    VesselID: number,
}

const BunkeringFileUpload = ({
    ReportID,
    VesselID,
}: BunkeringType) => {
    /** State variables */
    const [array, setArray] = useState<Array<any>>([]);
    const [count, setCount] = useState(0);
    const [newFileBool, setNewFileBool] = useState(false);
    /** State variables end */

    /** Show uploaded files */
    function handleAcceptedFiles(files: any, name: string, props: any) {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setArray(files);
    }

    /** useQueries */
    /**Bunkering object used for edit */
    const { data: BunkeringObject, isLoading: BunkeringObjectLoading, isError: BunkeringObjectError } = useQuery(
        [queryKeyes.vessel.BunkeringObject.key, VesselID, ReportID],
        async () => {
            return await loadBunkeringObject(VesselID, ReportID);
        },
        { staleTime: Infinity }
    )
    /** Queries end */

    /** Assign values to formik's initial object */
    const getInitialValues = (): any => {
        return ([{
            bdn_file: null,
            status: BDNConstant.PENDING,
            file_name: "",
            bunkering: [],
            vessel_reporting_information: ReportID
        }])
    }

    /** BDN Upload Formik object */
    const BDNFileUploadFromik = {
        initialValues: {
            bunkering: getInitialValues(),
        }
    }

    /** Post submit actions based on response */
    const handleResponse = (response: any) => {
        if (response.status === 201 || response.status === 200) {
            successToast("Data saved successfully!");
        }
    }

    /** POST request for Bunkering */
    const BunkeringFileUploadSubmit = (values: any) => {
        values.bunkering.forEach((bunker: any) => {
            if (array && array[0]) {
                bunker.bdn_file = array[0];
            } else {
                bunker.bdn_file = null;
            }
        })
        const formData = new FormData();
        values.bunkering.forEach((item: any) => {
            Object.keys(item).forEach(key => {
                formData.append(key, item[key]);
            });
        });
        apiMedia.post(`/bdn_file_upload/`, formData).then(res => {
            handleResponse(res);
        }).catch(err => {
            if (errResponse.includes(err.response.status)) {
                errorToast("Internal error occured, please contact the admin");
            }
        });
    }

    /** Filter Bunkering object to display unselected batches */
    const loadFilteredBunkeringObject = async (vesselId: number, reportId: number, values: any) => {
        try {
            const response = await apiGlobal.get(
                queryKeyes.vessel.BunkeringObject.url(vesselId, reportId)
            );
            let res;
            if (values.bunkering[0]?.bunkering?.length > 0) {
                let ids: any[] = [];
                values.bunkering.forEach((item: any) => {
                    item.bunkering.map((val: any) => ids.push(val));
                })
                res = response.data.filter((item: any) => !ids.includes(item.id));
            }
            else {
                res = response.data;
            }
            if (res.length <= 0) {
                setNewFileBool(true);
            } else {
                setNewFileBool(false);
            }
            return res;
        } catch (error) {
            console.log(error);
            return [];
        }
    }
    return (
        <>
            {BunkeringObjectLoading && <Loading message='Loading required data!' />}
            {BunkeringObjectError && <ErrorComponent message='Error loading component' />}
            {(!BunkeringObjectLoading && !BunkeringObjectError) &&
                <Card className='p-0 mb-0 border-0'>
                    <CardHeader className='p-2'>
                        <div className="text-center">
                            <Row>
                                <Col>
                                    <h4 className="page_title pos-start mb-0">BDN(s) Upload</h4>
                                </Col>
                            </Row>
                        </div>
                    </CardHeader>
                    <CardBody className='px-2'>
                        <Formik
                            initialValues={BDNFileUploadFromik.initialValues}
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(false);
                                BunkeringFileUploadSubmit(values);
                            }}
                        >
                            {props => (
                                <Form autoComplete="off" onSubmit={props?.handleSubmit}>
                                    <FieldArray name="bunkering">
                                        {({ push, remove }) => (
                                            <>
                                                {props?.values.bunkering.map((bunker: any, index: number) => {
                                                    return (
                                                        <>
                                                            <Row className='mb-2'>
                                                                <Col sm={12} className='d-flex align-items-center'>
                                                                    <Dropzone
                                                                        onDrop={acceptedFiles => {
                                                                            handleAcceptedFiles(acceptedFiles, `bunkering.${index}.bdn_file`, props);
                                                                        }}
                                                                    >
                                                                        {({ getRootProps, getInputProps }) => (
                                                                            <div
                                                                                className="needsclick"
                                                                                {...getRootProps()}
                                                                            >
                                                                                <input
                                                                                    {...getInputProps()}
                                                                                    name={`bunkering.${index}.bdn_file`}
                                                                                />
                                                                                <Button type="button" className="btn waves-effect btn-label waves-light mr-2" color='primary'>
                                                                                    <i className="mdi mdi-upload label-icon" />Upload BDN file</Button>
                                                                            </div>
                                                                        )}
                                                                    </Dropzone>
                                                                    {/* </Col>
                                                                <Col sm={8} className='d-flex align-items-center'> */}
                                                                    <strong className='mb-0 mr-2'>Select bunkers</strong>
                                                                    <AsyncSelect
                                                                        key={count}
                                                                        name={`bunkering.${index}.bunkering`}
                                                                        className='label-w-20'
                                                                        cacheOptions
                                                                        defaultOptions
                                                                        loadOptions={() => loadFilteredBunkeringObject(VesselID, ReportID, props?.values)}
                                                                        getOptionLabel={(e: any) => e.bunkering_supply_name}
                                                                        getOptionValue={(e: any) => e.id}
                                                                        menuPortalTarget={document.body}
                                                                        styles={customStyle}
                                                                        isMulti={true}
                                                                        onChange={
                                                                            (e: any) => {
                                                                                props?.setFieldValue(`bunkering.${index}.bunkering`, e.map((bunker: any) => { return bunker?.id }))
                                                                                setCount(count + 1);
                                                                            }
                                                                        }
                                                                        defaultValue={
                                                                            props?.values?.bunkering[index]?.bunkering?.length > 0 &&
                                                                            props?.values?.bunkering[index]?.bunkering.map((id: any) => {
                                                                                const matchedItem = BunkeringObject.find((item: any) => item.id === id);
                                                                                return matchedItem ? matchedItem : null;
                                                                            }).filter((item: any) => item !== null)
                                                                        }
                                                                    />
                                                                </Col>
                                                                <Col>
                                                                    {props?.values?.bunkering?.length === 1 ? null :
                                                                        <button type="button" className="btn justify_right">
                                                                            <i className='dripicons-trash icon_s18'
                                                                                onClick={() => {
                                                                                    remove(index);
                                                                                    setCount(count + 1);
                                                                                }} />
                                                                        </button>
                                                                    }
                                                                </Col>
                                                            </Row>
                                                            {index === (props?.values.bunkering.length - 1) && !newFileBool &&
                                                                <Label
                                                                    className="link_color_blue mt-2"
                                                                    onClick={() => {
                                                                        push({
                                                                            bdn_file: null,
                                                                            file_name: "",
                                                                            bunkering: [],
                                                                            vessel_reporting_information: ReportID
                                                                        });
                                                                    }}
                                                                >
                                                                    Add another file
                                                                </Label>
                                                            }
                                                        </>
                                                    )
                                                })}
                                            </>
                                        )}
                                    </FieldArray>
                                    <Row>
                                        <Col sm={{ size: 1, offset: 11 }} className='p-2'>
                                            <Button type="submit" color="primary" className="btn_size_cstm justify_right">Save</Button>
                                        </Col>
                                    </Row>
                                    <FormValuesDebug values={[props?.values, props?.errors, BDNFileUploadFromik.initialValues]} />
                                </Form>
                            )}
                        </Formik>
                    </CardBody>
                </Card>
            }
        </>
    )
}

export default BunkeringFileUpload