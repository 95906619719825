import React, { useState } from 'react';
import { Button, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import Select from 'react-select';
import { Formik } from 'formik';

export interface ComposeProps {
    compose: {
        title: string,
        category: { label: string, value: string },
        priority: { label: string, value: string },
        Date: Date,
        notification_message: string,
        user_group: { label: string, value: string },
        user: { label: string, value: string },
    } | null;
    handleBackClick: () => void;
}

const Compose: React.FC<ComposeProps> = ({ compose, handleBackClick }) => {
    const [formState, setFormState] = useState({
        title: compose?.title || '',
        category: compose?.category || null,
        priority: compose?.priority || null,
        Date: compose?.Date || new Date(),
        notification_message: compose?.notification_message || '',
        user_group: compose?.user_group || null,
        user: compose?.user || null,
    });

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setFormState((prevState) => ({ ...prevState, [name]: value }));
    };

    const handleSelectChange = (selectedOption: any, field: string) => {
        setFormState((prevState) => ({ ...prevState, [field]: selectedOption }));
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();
        console.log('Form data:', formState);
    };

    if (!compose) return <div>Click the compose button to create a new message.</div>;

    return (
        <>
            <div className='p-2'>
                <Button onClick={handleBackClick}>Back</Button>
                <Form onSubmit={handleSubmit}>
                    <FormGroup>
                        <Label for="title">Title</Label>
                        <Input
                            type="text"
                            name="title"
                            id="title"
                            value={formState.title}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="category">Category</Label>
                                <Select
                                    id="category"
                                    value={formState.category}
                                    onChange={(option) => handleSelectChange(option, 'category')}
                                    options={[
                                        { label: 'Critical', value: 'Critical' },
                                        { label: 'System', value: 'System' },
                                        { label: 'Maintainance', value: 'Maintainance' },
                                        { label: 'Daily Reporting', value: 'Daily Reporting' },
                                    ]}
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="priority">Priority</Label>
                                <Select
                                    id="priority"
                                    value={formState.priority}
                                    onChange={(option) => handleSelectChange(option, 'priority')}
                                    options={[
                                        { label: 'High', value: 'High' },
                                        { label: 'Medium', value: 'Medium' },
                                        { label: 'Low', value: 'Low' },
                                    ]}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <FormGroup>
                        <Label for="notification_message">Message</Label>
                        <Input
                            type="textarea"
                            name="notification_message"
                            id="notification_message"
                            value={formState.notification_message}
                            onChange={handleInputChange}
                        />
                    </FormGroup>
                    <Row>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="user_group">User Group</Label>
                                <Select
                                    id="user_group"
                                    value={formState.user_group}
                                    onChange={(option) => handleSelectChange(option, 'user_group')}
                                    options={[
                                        { label: 'Group 1', value: 'group1' },
                                        { label: 'Group 2', value: 'group2' },
                                    ]}
                                    required
                                />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="user">User</Label>
                                <Select
                                    id="user"
                                    value={formState.user}
                                    onChange={(option) => handleSelectChange(option, 'user')}
                                    options={[
                                        { label: 'User 1', value: 'user1' },
                                        { label: 'User 2', value: 'user2' },
                                    ]}
                                    required
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <div className='p-2'>
                        <Button className="m-2" type="submit" color="primary">Submit</Button>
                        <Button className="m-2" color="primary">Save</Button>
                        <Button className="m-2">Delete</Button>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default Compose;
