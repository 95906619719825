import React, { useState } from 'react';
import { Card, Navbar, Table } from 'reactstrap';
import { FaInbox, FaPaperPlane, FaTrash, FaDraftingCompass, FaExclamationTriangle } from 'react-icons/fa'; // Importing icons from react-icons
import Layout from 'HorizontalMenu/Menu';
import EmailDetails from './EmailDetails';
import Compose from './Compose';
import { useQuery } from 'react-query';
import { queryKeyes } from 'shared/queryKeys';
import { NotificationList } from 'VesselMaster/vesselMaster.hooks';
import Loading from 'Components/Loading';
import ErrorComponent from 'Components/ErrorComponent';
import Inbox from './Inbox';

const Notification = () => {
    const {
        data: notificationObj = [],
        isLoading: notificationObjLoading,
        isError: notificationObjError,
    }: { data: any[]; isLoading: any; isError: any } = useQuery(
        [queryKeyes.notification.NotificationList],
        async () => await NotificationList(),
        {
            enabled: true,
            staleTime: Infinity,
        }
    );
    console.log("notificationObj", notificationObj);

    const tabs = [
        { name: 'Compose', icon: null },
        { name: 'Inbox', icon: <FaInbox /> },
        { name: 'Sent', icon: <FaPaperPlane /> },
        { name: 'Draft', icon: <FaDraftingCompass /> },
        { name: 'Trash', icon: <FaTrash /> },
        { name: 'Spam', icon: <FaExclamationTriangle /> },
    ];

    const [selectedEmail, setSelectedEmail] = useState(null);
    const [compose, setCompose] = useState(false); // Default to false (no compose form)

    function handleEmailClick(notificationObj: { id: number; title: string; date: string; current_state: boolean; category: string; priority: string; }): void {
        setSelectedEmail(notificationObj);
        setCompose(false); // Close compose if an email is clicked
    }

    function handleComposeClick() {
        setCompose(true); // Set compose to true when "Compose" is clicked
        setSelectedEmail(null); // Deselect any selected email
    }

    const handleComposeBackClick = () => {
        setCompose(false); // Go back from compose view
    };

    const handleBackClick = () => {
        setSelectedEmail(null);
    };

    return (
        <>
            {notificationObjLoading && <Loading message='Loading required data!' />}
            {notificationObjError && <ErrorComponent message='Error loading data' />}
            <Layout children={Navbar} />
            <div className='page-content'>
                <h2 className="mb-0">Notifications</h2>
                <div className="main-container d-flex flex-wrap">
                    {/* Sidebar */}
                    <div className="col-md-2 p-3 bg-light border-right">
                        <ul className="list-unstyled">
                            {tabs.map((tab, index) => (
                                <li key={index} className="mb-2">
                                    {tab.name === 'Compose' ? (
                                        <button className="btn btn-primary w-100" onClick={handleComposeClick}>
                                            {tab.name}
                                        </button>
                                    ) : (
                                        <div className="d-flex align-items-center">
                                            {tab.icon}
                                            <span className="ml-1" onClick={handleBackClick}>{tab.name}</span>
                                        </div>
                                    )}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Main Content */}
                    <div className="col-md-10 ps-2 pe-5">
                        <Card className="mb-3 shadow-sm">
                            {compose ? (
                                <Compose handleBackClick={handleComposeBackClick} compose={{
                                    title: '',
                                    category: undefined,
                                    priority: undefined,
                                    Date: undefined,
                                    notification_message: undefined,
                                    user_group: undefined,
                                    user: undefined
                                }} />
                            ) : selectedEmail ? (
                                <EmailDetails email={selectedEmail} handleBackClick={handleBackClick} />
                            ) : (
                                <Inbox 
                                    notificationObj={notificationObj} 
                                    handleEmailClick={handleEmailClick} 
                                />
                            )}
                        </Card>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Notification;
