import React from 'react';
import { Table } from 'reactstrap';
import BadgeComponent from './RenderBadge';

// Define the type for notification object
interface NotificationType {
    id: number;
    title: string;
    date: string;
    current_state: boolean;
    category: string;
    priority: string;
}

// Define prop types for the EmailList component
interface EmailListProps {
    notificationObj: NotificationType[];
    handleEmailClick: (notification: NotificationType) => void;
}


const Inbox: React.FC<EmailListProps> = ({ notificationObj, handleEmailClick }) => {
    return (
        <Table className="table table-striped table-hover">
            <thead className="thead-light">
                <tr>
                    <th style={{ width: '5%' }}>#</th>
                    <th style={{ width: '10%' }} className='text-center'>Category</th>
                    <th style={{ width: '10%' }} className='text-center'>Priority</th>
                    <th style={{ width: '15%' }}>Date & Time</th>
                    <th style={{ width: '60%' }}>Title</th>
                </tr>
            </thead>
            <tbody>
                {notificationObj.map((notification: any) => (
                    <tr
                        key={notification.id}
                        onClick={() => handleEmailClick(notification)}
                        className={notification.current_state ? 'text-muted' : 'font-weight-bold'}
                        style={{ cursor: 'pointer' }}
                    >
                        <td><input type="checkbox" /></td>
                        <td className='text-center'>
                            <BadgeComponent text={notification.category} type="category" categoryColors={{}} priorityColors={{}} />
                        </td>
                        <td className='text-center'>
                            <BadgeComponent text={notification.priority} type="priority" categoryColors={{}} priorityColors={{}} />
                        </td>
                        <td>{new Date(notification.date).toLocaleString()}</td>
                        <td>{notification.title}</td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default Inbox;
